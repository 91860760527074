body header {
  font-family: 'Open sans', sans-serif;
  position: relative;
  z-index: 500;
  background: #FFF;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
}
body header .header-cntnr {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
body header .header-cntnr .block-right {
  display: flex;
  align-items: center;
}
body header .header-cntnr a.contact {
  background: #88d535;
  padding: 8px 20px;
  border-radius: 2px;
  transition: all .3s;
  font-size: 18px;
  color: #FFF;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  border-left: 10px solid #619d21;
  position: relative;
}
body header .header-cntnr a.contact .shape {
  border-style: solid;
  border-width: 20px 0 20px 5px;
  border-color: transparent transparent transparent #619d21;
  position: absolute;
  left: 0;
  top: 0;
}
body header .header-cntnr a.contact i {
  font-size: 20px;
  margin-right: 10px;
}
body header .header-cntnr a.contact:hover {
  background: #6eb225;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  color: #fff;
}
body header .header-cntnr .menu-pane ul li {
  position: relative;
}
body header .header-cntnr .menu-pane ul a {
  position: relative;
  display: block;
  text-transform: uppercase;
  color: #333333;
  font-weight: 600;
  transition: all .3s;
}
body header .header-cntnr .menu-pane ul a.contact {
  color: #fff;
}
body header .header-cntnr .menu-pane ul a:hover {
  color: #ffa301;
}
@media (max-width: 767px) {
  body header .header-cntnr .menu-pane ul li a.contact:hover {
    color: #fff;
  }
}
body.sticking header {
  opacity: 0;
  transition: top .6s;
  position: fixed;
  z-index: 501;
  right: 0;
  left: 0;
  top: -100%;
}
@media (min-width: 1200px) {
  body .header-cntnr {
    max-width: 1140px;
  }
  body .logo-cntnr img {
    max-width: 185px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  body .header-cntnr {
    max-width: 940px;
  }
  body .logo-cntnr img {
    max-width: 160px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  body .header-cntnr {
    padding: 10px 25px;
  }
}
@media (min-width: 992px) {
  body header .header-cntnr {
    padding: 15px 0;
  }
  body header .header-cntnr .menu-pane {
    flex: 1;
    padding: 0 1%;
  }
  body header .header-cntnr .menu-pane ul {
    display: flex;
    justify-content: space-around;
  }
  body header .header-cntnr .menu-pane ul li > a {
    padding: 10px 0;
  }
  body header .header-cntnr #menu-toggle-btn {
    display: none;
  }
  body #mobile-menu-mask {
    display: none;
  }
}
@media (max-width: 991px) {
  body header .header-cntnr .logo-cntnr img {
    max-height: 35px;
    width: auto;
  }
  body header .header-cntnr .contact {
    margin-right: 15px;
  }
  body header .header-cntnr #menu-toggle-btn {
    width: 45px;
    height: 45px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  body header .header-cntnr #menu-toggle-btn i {
    font-size: 2.2em;
    color: #304966;
  }
  body header .header-cntnr .menu-pane {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #FFF;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;
  }
  body header .header-cntnr .menu-pane ul.menu {
    padding-top: 10px;
    margin-bottom: 15px;
    text-align: center;
  }
  body header .header-cntnr .menu-pane ul.menu li a:not(.contact) {
    padding: 7px 0;
  }
  body header .header-cntnr .menu-pane ul.menu li a:not(.contact):hover {
    background: #304966;
    color: #FFF;
  }
  body header .header-cntnr .menu-pane.active {
    overflow-y: auto;
    max-height: calc(100vh - 110px);
  }
  body header .header-cntnr .contact .btn {
    font-size: 1em;
  }
  body header .header-cntnr .social-networks {
    display: none;
  }
  body #mobile-menu-mask {
    opacity: 0;
    background: #000;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: opacity .4s;
  }
  body #mobile-menu-mask.active {
    position: fixed;
    opacity: .3;
  }
}
@media (max-width: 767px) {
  body .header-cntnr {
    padding: 3px 8px;
  }
  body .block-right .contact {
    display: none;
  }
}
body.sticked header {
  opacity: 1;
  top: 0;
}
body.sticked header .header-cntnr .logo-cntnr img {
  max-height: 35px;
  width: auto;
}
@media (max-width: 767px) {
  body.sticked header .header-cntnr {
    padding: 5px 10px;
  }
}
