
@import "vars.less";
@import "mixins.less";

body{
    header {
        font-family: 'Open sans', sans-serif;
        position: relative;
        z-index: 500;
        background: #FFF;
        box-shadow: @shadow;
        .header-cntnr{
            position: relative;
            display:flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;
            .block-right {
                display: flex;
                align-items: center;
            }
            a.contact {
                background: @green-light;
                padding: 8px 20px;
                border-radius: 2px;
                transition: all .3s;
                font-size: 18px;
                color: #FFF;
                text-shadow: 2px 2px 10px rgba(0,0,0,.5);
                border-left: 10px solid darken(@green-light, 15%);
                position: relative;
                .shape {
                    border-style: solid;
                    border-width: 20px 0 20px 5px;
                    border-color: transparent transparent transparent darken(@green-light, 15%);
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                i {
                    font-size: 20px;
                    margin-right: 10px;
                }
                &:hover {
                    background: darken(@green-light, 10%);
                    box-shadow: @shadow;
                    color: #fff;
                }

            }
            .menu-pane{
                ul{
                    li{
                        position : relative;
                    }
                    a{
                        position : relative;
                        display : block;
                        text-transform: uppercase;
                        color: @grey-text;
                        font-weight: 600;
                        transition: all .3s;
                        &.contact {
                            color: #fff;
                        }
                        &:hover {
                            color: @orange;
                        }
                    }
                }
                @media @smdwn {
                    ul li a.contact:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
    &.sticking{
        header{
            opacity: 0;
            transition : top .6s;
            position: fixed;
            z-index: 501;
            right:0;
            left:0;
            top : -100%;
        }
    }

    @media @lgup {
        .header-cntnr {
            max-width: 1140px;
        }
        .logo-cntnr {
            img {
                max-width: 185px;
            }
        }
    }
    @media @md {
        .header-cntnr {
            max-width: 940px;

        }
        .logo-cntnr {
            img {
                max-width: 160px;
            }
        }
    }
    @media @sm {
        .header-cntnr {
            padding: 10px 25px;
        }
    }
    @media @mdup{
        header{
            .header-cntnr{
                padding: 15px 0;
                .menu-pane{
                    flex:1;
                    padding : 0 1%;
                    ul{
                        display:flex;
                        justify-content: space-around;
                        li{
                            > a{
                                padding: 10px 0;
                            }
                        }
                    }
                }
                #menu-toggle-btn{
                    display : none;
                }
            }
        }
        #mobile-menu-mask{
            display : none;
        }
    }
    @media @mddwn{
        header{
            .header-cntnr{

                .logo-cntnr{
                    img{
                        max-height: 35px;
                        width: auto;
                    }
                }
                .contact {
                    margin-right: 15px;
                }
                #menu-toggle-btn{
                    width: 45px;
                    height: 45px;
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i {
                        font-size: 2.2em;
                        color: @dark-blue;
                    }
                }
                .menu-pane{
                    position: absolute;
                    top : 100%;
                    left : 0;
                    right : 0;
                    background: #FFF;
                    max-height : 0;
                    overflow : hidden;
                    transition :  max-height .3s;
                    ul.menu{
                        padding-top: 10px;
                        margin-bottom : 15px;
                        text-align: center;
                        li{
                            a:not(.contact){
                                padding : 7px 0;
                                &:hover {
                                    background: @dark-blue;
                                    color: #FFF;
                                }
                            }
                        }
                    }
                    &.active{
                        overflow-y : auto;
                        max-height : ~"calc(100vh - 110px)";
                    }
                }
                .contact{
                    .btn{
                        font-size: 1em;
                    }
                }
                .social-networks{  
                    display: none;
                }
            }
        }
        #mobile-menu-mask{
            opacity: 0;
            background: #000;
            z-index: 10;
            top : 0; left : 0; bottom : 0; right: 0;
            transition :  opacity .4s;
            &.active{
                position: fixed;
                opacity: .3;
            }
        }
    }
    @media @smdwn  {
        .header-cntnr {
            padding: 3px 8px;
        }
        .block-right .contact {
            display: none;
        }
    }
    &.sticked{
        header{
            opacity: 1;
            top: 0;
            .header-cntnr{
                .logo-cntnr {
                    img {
                        max-height: 35px;
                        width: auto;
                    }
                }
                @media @smdwn {
                    padding: 5px 10px;
                }
            }
        }
    }
}